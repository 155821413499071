import { useState } from "react";
import Window from "./Window";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const [winType, setWinType] = useState('');
    const navigate = useNavigate();

    const handleWindowClose = () => {
        setWinType('');
    }
    
    return(
        <div className='footer'>
            <Window type={winType} close={() => handleWindowClose()}/>
            <div className='footer-column'>
                <h4>Site Map</h4>
                <ul>
                    <li onClick={() => navigate('/')}>Home</li>
                    <li onClick={() => navigate('/services')}>Services</li>
                    <li onClick={() => navigate('/blog')}>Blog</li>
                    <li onClick={() => navigate('/contact')}>Contact</li>
                </ul>
            </div>
            <div className='footer-column'>
                <h4>Quick Links</h4>
                <ul>
                    <li onClick={() => setWinType('featurenotenabled')}>Get a Quote</li>
                    <li onClick={() => setWinType('featurenotenabled')}>View Estimate</li>
                    <li></li>
                </ul>
            </div>
            <div className='footer-column'>
                <h4>Social Media</h4>
                <ul>
                    <li onClick={() => setWinType('featurenotenabled')}>Facebook</li>
                    <li onClick={() => setWinType('featurenotenabled')}>Instagram</li>
                </ul>
            </div>
            <div className='footer-column'>
                <h4>Legal Information</h4>
                <ul>
                    <li onClick={() => setWinType('termsofservice')}>Terms of Service</li>
                    <li onClick={() => setWinType('privacypolicy')}>Privacy Policy</li>
                    <li>HTEX Roofing ©2024</li>
                    <li>Site by Eclipse Pixel</li>
                </ul>
            </div>
        </div>
    )
}

export default Footer;