import { useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";

const Home = () => {
    const pub_url = process.env.PUBLIC_URL;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        zip: '',
        message: ''
    })
    const timeoutRef = useRef(null);

    const cards = [
        <div className='carousel-card' key='1'>
            <h5>James R</h5>
            <p>I am thoroughly impressed with the exceptional service provided by HTEX Roofing for my asphalt shingle full tear down project.  From the initial consultation to the final installation, their team was professional, courteous, and meticulous in their work.  The crew was efficient, clean, and respectful of my property throughout the entire process.  The new roof looks fantastic and I'm pleased with the quality of the materials and workmanship.  Their competetive pricing and prompt communication made the experience seamless from start to finish.  I highly recommend HTEX Roofing to anyone in need of a reliable and trustworthy roofing company - 5 stars is not enough, they deserve 10!</p>
            <div className='rating'>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
            </div>
        </div>,
        <div className='carousel-card' key='2'>
            <h5>Tony F</h5>
            <p>Great worked performed reasonable pricing and same day service</p>
            <div className='rating'>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-half.png'} alt='star'/>
            </div>
        </div>,
        <div className='carousel-card' key='3'>
            <h5>Richard H</h5>
            <p>They came to give me an estimate on a few shingle repairs after Hurricane Beryl.  They took some photos of everything they could find with a drone which was pretty neat while one of them also went up on th roof to get a better look.  They showed me photos of everything they saw.  I thought the pricing was decent and gave them the go ahead.  They worked quickly and before the end of the day my roof was all patched up.  No leaks during these recent patches of rain so money well spent in my opinion.</p>
            <div className='rating'>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
            </div>
        </div>,
        <div className='carousel-card' key='4'>
            <h5>Frank R</h5>
            <p>I called those guys they came out the same day and also had my roof repair done the same day I had 2 leaks they took care of me and had no issues with the storm</p>
            <div className='rating'>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
                <img src={pub_url+'/images/star-full.png'} alt='star'/>
            </div>
        </div>,
    ]

    useEffect(() => {
        if (!isHovered) {
            timeoutRef.current = setTimeout(
                () => {
                    setIsAnimating(true);
                    setTimeout(() => {
                        setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
                        setIsAnimating(false);
                    }, 500);
                },
                5000
            );
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [currentIndex, isHovered, cards.length]);

    const handleSubmit = async(e) => {
        console.log('form submitted: pending response')
        e.preventDefault();

        try{
            const response = await fetch('https://formsubmit.co/htexroofing@gmail.com', {
                method: 'POST',
                body: formData,
            });
            if(response.ok){
                alert('Thank you for contacting us!');
                setFormData({
                    name: '',
                    phone: '',
                    zip: '',
                    message: ''
                });
            }
            else{
                alert('Something went wrong')
            }
        }
        catch(error){
            alert('Something went wrong')
        }
    }

    return(
        <div className='page home'>
            <div className='hero' style={{ backgroundImage: `url(${pub_url}/images/hero.png)` }}>
                <div>
                    <h1>YOUR TRUSTED ROOFING CONTRACTORS</h1>
                    <p>Your roof is the primary defense against the elements for your property.  When you need a repair or installation, it should be done by someone you can trust.  It should be HTEX Roofing.</p>
                </div>
                <div className='cta'>
                    <p>GET YOUR FREE ESTIMATE TODAY</p>
                    <h2>(281) 870 3303</h2>
                </div>
            </div>
            <div className='service-overview'>
                <div className='service-overview-bg'/>
                <div className='overview-card'>
                    <div className='overview-image' style={{ backgroundImage: `url(${pub_url}/images/img1.jpg)` }}/>
                    <div className='overview-card-content'>
                        <h1>RESIDENTIAL ROOFING</h1>
                        <p>We specialize in residential roofing with a focus on quality and durability. Our services include new roof installations, repairs for leaks and damage, and regular maintenance. Using high-grade materials like asphalt shingles and metal roofing, we ensure your home remains protected against the elements while enhancing its aesthetic appeal.</p>
                    </div>
                </div>
                <div className='overview-card'>
                    <div className='overview-image' style={{ backgroundImage: `url(${pub_url}/images/img5.jpg)` }}/>
                    <div className='overview-card-content'>
                        <h1>COMMERCIAL ROOFING</h1>
                        <p>Our commercial roofing services are designed to meet the demands of various business properties. We handle flat roofs, TPO and EPDM installations, as well as repairs and maintenance for minimal downtime. Our solutions are tailored to ensure durability and efficiency, helping to protect your commercial space from weather and wear while supporting your operational needs.</p>
                    </div>
                </div>
            </div>
            <div className='warranty-section'>
                <img src={pub_url+'/images/guaranteed.png'} alt='warranty-image'/>
                <div>
                    <h4>WE MAKE OUR CUSTOMERS HAPPY WITH</h4>
                    <ul>
                        <li>10 Year Workmanship Warranty and 20 year Manufacturer Warranty on all new roofs!</li>
                        <li>Flexible Payment Options</li>
                        <li>Professional and Affordable Services</li>
                        <li><strong>FREE</strong> Estimates</li>
                    </ul>
                </div>
            </div>
            <div className='review-carousel'>
                <h3>SEE WHAT OUR CUSTOMERS SAY ABOUT US</h3>
                <div className={isAnimating? 'carousel-content fade-out' : 'carousel-content fade-in'} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    {cards[currentIndex]}
                </div>
                <div className='carousel-indicator'>
                    {cards.map((_, index) => (
                        <span key={index} className={index === currentIndex ? 'active indicator' : 'indicator'} onClick={() => setCurrentIndex(index)}/>
                    ))}
                </div>
                <div className='carousel-background'/>
                <div>
                    <button>{'Click to See More Reviews >'}</button>
                </div>
            </div>
            <div className='about-section'>
                <h5>MORE ABOUT US</h5>
                <div className='flex-row'>
                    <div className='flex-col'>
                        <h6>WHO WE ARE</h6>
                        <p>At HTEX Roofing, we are dedicated roofing professionals with over 20 years of experience in the industry. Our team is committed to delivering exceptional craftsmanship and superior customer service. From residential homes to large commercial projects, we have the expertise to handle all types of roofing needs. We take pride in our reputation for reliability, quality, and integrity, ensuring that every project we undertake is completed to the highest standards.</p>
                    </div>
                    <div className='flex-col'>
                        <h6>OUR VALUES</h6>
                        <p>Our values are the foundation of our success at HTEX Roofing. We believe in honesty, transparency, and accountability in all our interactions. Our commitment to excellence drives us to continually improve and innovate, providing our clients with the best possible roofing solutions. We prioritize safety, sustainability, and customer satisfaction, ensuring that every project is completed responsibly and to our clients' utmost satisfaction. We strive to build lasting relationships with our customers, based on trust and mutual respect.</p>
                    </div>
                </div>
            </div>
            <div className='products'>
                <h4>THE BRANDS WE TRUST</h4>
                <Marquee speed={25} gradient={true}>
                    <img src={pub_url+'/images/logos/pr1.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr2.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr3.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr4.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr5.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr6.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr7.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr8.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr9.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr10.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr11.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr12.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr13.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr14.png'} alt='brand-logo'/>
                    <img src={pub_url+'/images/logos/pr15.png'} alt='brand-logo'/>
                </Marquee>
            </div>
            <div className='mini-gallery'>
                <img src={pub_url+'/images/img4.png'}/>
                <img src={pub_url+'/images/img3.png'}/>
                <img src={pub_url+'/images/img2.png'}/>
                <img src={pub_url+'/images/img1.png'}/>
                <img src={pub_url+'/images/img5.png'}/>
                <img src={pub_url+'/images/img6.png'}/>
                <img src={pub_url+'/images/img7.png'}/>
                <img src={pub_url+'/images/img8.png'}/>
            </div>
            <div className='contact' style={{ backgroundImage: `url(${pub_url}/images/contact.png)` }}>
                <div className='contact-info'>
                    <div>
                        <h4>GET A QUOTE</h4>
                        <a href="mailto:htextroofing@gmail.com">htexroofing@gmail.com</a>
                        <a href="tel:2818703303">(281) 870 3303</a>
                    </div>
                    <div className='contact-form' id='contact-form'>
                        <form onSubmit={handleSubmit}>
                            <input type='text' placeholder='Your Name' value={formData.name} onChange={(e) => setFormData({...formData, name: e.target.value})}/>
                            <div>
                                <input type='text' placeholder='Phone Number' value={formData.phone} onChange={(e) => setFormData({...formData, phone: e.target.value})}/>
                                <input type='text' placeholder='Zip Code' value={formData.zip} onChange={(e) => setFormData({...formData, zip: e.target.value})}/>
                            </div>
                            <textarea placeholder='How can we help?' value={formData.message} onChange={(e) => setFormData({...formData, message: e.target.value})}/>
                            <button type='submit'>SUBMIT</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;