import Footer from "./Footer";
import Nav from "./Nav";
import '../style/Services.css';
import { useState } from "react";

const Services = () => {

    const pub_url = process.env.PUBLIC_URL;
    const [type, setType] = useState(false);

    function changeType(val){
        let btn = document.getElementsByClassName('banner-btn');
        let active = document.getElementsByClassName('banner-active')[0];
        let content = document.getElementsByClassName('service-content')[0];

        content.style.paddingLeft = '5vw';
        content.style.paddingRight = '15vw';
        content.style.opacity = '0';
        active.classList.remove('banner-active');
        if(val === true){
            btn[1].classList.add('banner-active')
        }
        else{
            btn[0].classList.add('banner-active');
        }
        setTimeout(function(){
            setType(val);
            setTimeout(function(){
                content.style.paddingLeft = '10vw';
                content.style.paddingRight = '10vw';
                content.style.opacity = '1';
            }, 500)
        }, 500)
    }

    if(!type){
        return(
            <div className='wrapper'>
                <Nav/>
                <div className='top-banner' style={{ backgroundImage: `url(${pub_url}/images/hero.png)` }}>
                    <div className='banner-menu'>
                        <button onClick={() => changeType(false)} className='banner-btn banner-active'><span>RESIDENTIAL</span><div/></button>
                        <button onClick={() => changeType(true)} className='banner-btn'><span>COMMERCIAL</span><div/></button>
                    </div>
                </div>
                <div className='service-content'>
                    <div className='service-tile'>
                        <h1>ROOF INSTALLATION</h1>
                        <p>We provide expert roof installation services using high-quality materials tailored to your home's needs. Our experienced team ensures a seamless process, delivering a durable and aesthetically pleasing roof that enhances your home's curb appeal and protects it for years to come.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>ROOF REPAIR</h1>
                        <p>Our roof repair services address issues such as leaks, damaged shingles, and storm damage. We quickly diagnose and fix problems to prevent further damage, ensuring your home remains safe and dry. Our goal is to extend the life of your existing roof with effective and reliable repairs.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>ROOF REPLACEMENT</h1>
                        <p>When repairs are no longer sufficient, our roof replacement service offers a complete overhaul with modern, durable materials. We guide you through selecting the best options for your home, ensuring a smooth and efficient process that minimizes disruption and maximizes satisfaction.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>ROOF INSPECTION</h1>
                        <p>Regular roof inspections help identify potential issues before they become costly repairs. Our thorough inspections assess the condition of your roof, providing you with a detailed report and recommendations for maintenance or necessary repairs to keep your roof in optimal condition.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>ROOF MAINTENANCE</h1>
                        <p>Preventative roof maintenance is key to extending the life of your roof. Our maintenance services include cleaning, minor repairs, and routine inspections to keep your roof in top shape, preventing major issues and ensuring it performs well year-round.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>STORM DAMAGE REPAIR</h1>
                        <p>We specialize in repairing storm-damaged roofs, providing prompt and efficient services to restore your roof's integrity. Our team assesses the damage, works with insurance companies, and performs the necessary repairs to get your home back to normal quickly.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>GUTTER INSTALLATION AND REPAIR</h1>
                        <p>Proper gutter installation and maintenance are crucial for protecting your roof and foundation. We offer gutter installation and repair services to ensure effective water drainage, preventing water damage and preserving the structural integrity of your home.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>ROOF VENTILATION</h1>
                        <p>Proper roof ventilation is essential for maintaining a healthy and energy-efficient home. Our ventilation services include installing and repairing vents to regulate temperature and moisture levels, preventing issues like mold growth and extending the lifespan of your roof.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>SIDING REPLACEMENT</h1>
                        <p>Siding replacement enhances the exterior appearance of your home while providing protection against the elements. Our siding services offer a range of materials and styles to choose from, ensuring a durable and visually appealing upgrade that complements your home’s architecture.</p>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
    else{
        return(
            <div className='wrapper'>
                <Nav/>
                <div className='top-banner' style={{ backgroundImage: `url(${pub_url}/images/hero.png)` }}>
                    <div className='banner-menu'>
                        <button onClick={() => changeType(false)} className='banner-btn banner-active'><span>RESIDENTIAL</span><div/></button>
                        <button onClick={() => changeType(true)} className='banner-btn'><span>COMMERCIAL</span><div/></button>
                    </div>
                </div>
                <div className='service-content'>
                    <div className='service-tile'>
                        <h1>COMMERCIAL ROOF INSTALLATION</h1>
                        <p>We offer professional roof installation services for commercial properties, utilizing durable and high-performance materials. Our skilled team ensures a seamless installation process, providing a roof that offers long-lasting protection and enhances the building’s overall efficiency and appearance.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>COMMERCIAL ROOF REPAIR</h1>
                        <p>Our commercial roof repair services address issues such as leaks, punctures, and storm damage. We provide timely and effective repairs to prevent further damage and disruptions to your business operations, ensuring your roof remains in optimal condition.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>COMMERCIAL ROOF REPLACEMENT</h1>
                        <p>When repairs are insufficient, our roof replacement services offer a complete renewal of your commercial roof. We work with you to select the best materials and systems for your property, ensuring minimal disruption to your business while delivering a durable and reliable roofing solution.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>COMMERCIAL ROOF INSPECTION</h1>
                        <p>Regular roof inspections are crucial for maintaining the health of your commercial roof. Our detailed inspections identify potential issues early, providing you with comprehensive reports and recommendations for maintenance or repairs to avoid costly damage and extend the roof’s lifespan.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>COMMERCIAL ROOF MAINTENANCE</h1>
                        <p>Preventative maintenance is key to extending the life of your commercial roof. Our maintenance services include routine inspections, cleaning, and minor repairs to keep your roof in top condition, preventing major issues and ensuring it performs well year-round.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>EMERGENCY ROOF REPAIR</h1>
                        <p>We provide emergency roof repair services to address urgent issues such as severe leaks or storm damage. Our team responds quickly to mitigate damage, restore your roof’s integrity, and minimize disruptions to your business operations.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>FLAT ROOFING SYSTEMS</h1>
                        <p>We specialize in flat roofing systems for commercial properties, offering solutions such as TPO, EPDM, and modified bitumen. Our expert installation and maintenance services ensure a durable and energy-efficient roof that meets the unique needs of flat-roofed buildings.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>METAL ROOFING SYSTEMS</h1>
                        <p>Metal roofing is a popular choice for commercial properties due to its durability and low maintenance requirements. We offer installation, repair, and maintenance services for metal roofing systems, providing a long-lasting and energy-efficient roofing solution.</p>
                    </div>
                    <div className='service-tile'>
                        <h1>COMMERCIAL ROOF COATINGS</h1>
                        <p>Roof coatings can extend the life of your existing roof and improve its performance. Our roof coating services provide a protective layer that enhances weather resistance, reduces energy costs, and helps prevent leaks and other damage.</p>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }

}

export default Services;