import Nav from "./Nav";
import '../style/Main.css';
import Footer from "./Footer";
import Home from "./Home";

const Main = () => {
    return(
        <div className='wrapper'>
            <Nav/>
            <Home/>
            <Footer/>
        </div>
    )
}

export default Main;