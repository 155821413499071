import { useParams } from "react-router-dom";
import { db } from "../Firebase";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import Footer from "./Footer";
import Nav from "./Nav";
import '../style/Blog.css';

const ArticleReader = () => {
    const { id } = useParams();
    const [article, setArticle] = useState(null);
    const pub_url = process.env.PUBLIC_URL;

    useEffect(() => {
        async function fetchArticle() {
            const docRef = doc(db, "blog-articles", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setArticle(docSnap.data());
            } else {
                console.log("No such document!");
            }
        }

        fetchArticle();
    }, [id]);

    return (
        <div className='wrapper'>
            <Nav />
            {article ? (
                <div className='article'>
                    <img src={pub_url + article.ImageRef} alt='article-photo' />
                    <div className='article-content'>
                        <h1>{article.Title}</h1>
                        {article.Paragraphs.map((paragraph, index) => (
                            <div key={index}>
                                <h4>{paragraph.Title}</h4>
                                <p>{paragraph.Paragraph}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
            <Footer />
        </div>
    );
}

export default ArticleReader;
