import Footer from "./Footer";
import Nav from "./Nav";
import '../style/Contact.css';
import { useState } from "react";

const Contact = () => {
    const pub_url = process.env.PUBLIC_URL;
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [zip, setZip] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('number', number);
        formData.append('zip', zip);
        formData.append('message', message);

        try {
            const response = await fetch('https://formsubmit.co/htexroofing@gmail.com', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setName('');
                setNumber('');
                setZip('');
                setMessage('');
            } else {
                console.log(response);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return(
        <div className='wrapper'>
            <Nav/>
            <div className='contact-content' style={{ backgroundImage: `url(${pub_url}/images/contact.png)` }}>
                <div className='contact-info'>
                    <div>
                        <h5>LET'S WORK TOGETHER</h5>
                        <p>Got questions or ready to start your roofing project? Our team is here to help! Contact us today to discuss your needs and receive expert advice and a free estimate. Let us provide you with the quality roofing services your property deserves. Reach out now and let's get started!</p>
                        <p>htexroofing@gmail.com</p>
                        <p>(281) 870 3303</p>
                    </div>
                    <div className='contact-form'>
                        <form method='POST' action='https://formsubmit.co/htexroofing@gmail.com'>
                            <input type='text' placeholder='Your Name' value={name} onChange={(e) => setName(e.target.value)}/>
                            <div>
                                <input type='text' placeholder='Your Phone Number' value={number} onChange={(e) => setNumber(e.target.value)}/>
                                <input type='text' placeholder='Your Zip Code' value={zip} onChange={(e) => setZip(e.target.value)}/>
                            </div>
                            <textarea placeholder='How can we help?' value={message} onChange={(e) => setMessage(e.target.value)}/>
                            <button type='submit'>SUBMIT</button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Contact;