import { useNavigate } from "react-router-dom";

const Nav = () => {
    const pub_url = process.env.PUBLIC_URL;
    const navigate = useNavigate();

    return(
        <div className='nav'>
            <img src={pub_url + '/images/logo-b.png'} alt='logo'/>
            <div className='nav-menu'>
                <button onClick={() => navigate('/')}>Home</button>
                <button onClick={() => navigate('/services')}>Services</button>
                <button onClick={() => navigate('/blog')}>Blog</button>
                <button onClick={() => navigate('/contact')}>Contact</button>
            </div>
        </div>
    )
}

export default Nav;
