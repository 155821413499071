import { useNavigate } from 'react-router-dom';
import Footer from "./Footer";
import Nav from "./Nav";
import '../style/Blog.css';
import { db } from "../Firebase";
import { collection, getDocs, query } from "firebase/firestore";
import { useEffect, useState } from "react";

const BlogMain = () => {
    const [docs, setDocs] = useState([]);
    const pub_url = process.env.PUBLIC_URL;
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            const q = query(collection(db, 'blog-articles'));
            const querySnapshot = await getDocs(q);
            let arr = [];
            querySnapshot.forEach((doc) => {
                arr.push({ id: doc.id, ...doc.data() });
            });
            setDocs(arr);
        }

        fetchData();
    }, []);

    const handleReadMore = (id) => {
        navigate(`/blog/read/${id}`);
    };

    const Articles = docs.map((item, index) => {
        return (
            <div className='article-tile' key={index}>
                <img src={pub_url + item.ImageRef} alt='article-photo' />
                <div>
                    <h1>{item.Title}</h1>
                    <p>{item.Summary}</p>
                    <button onClick={() => handleReadMore(item.id)}>Keep Reading</button>
                </div>
            </div>
        );
    });

    return (
        <div className='wrapper'>
            <Nav />
            <div className='featured-articles'>
                <h5>FEATURED ARTICLES</h5>
                <div className='article-row'>
                    {/* Sample static articles */}
                    <div className='article-tile'>
                        <img src='https://allstarroofingandrepair.com/wp-content/uploads/2023/08/How-Much-Hail-Damage-to-Replace-Roof-1024x681.jpg'/>
                        <div>
                            <h1>Signs Your Roof Needs Immediate Attention</h1>
                            <p>Identifying the signs that your roof needs immediate attention can help Houston homeowners avoid extensive damage and costly repairs by addressing issues promptly.</p>
                            <button onClick={() => navigate('/blog/read/W81rnZkKe9W6uWTMaXWf')}>Keep Reading</button>
                        </div>
                    </div>
                    <div className='article-tile'>
                        <img src='https://www.jacksoncontractingsite.com/wp-content/uploads/2022/12/Roofing-scaled.jpg'/>
                        <div>
                            <h1>Roof Replacement vs. Repair:  Making the Right Decision for Your Houston Home</h1>
                            <p>Deciding between roof replacement and repair can be challenging for Houston homeowners. Understanding the factors involved can help make an informed decision that ensures long-term protection and value.</p>
                            <button onClick={() => navigate('/blog/read/NZErrs4t2voStrA4M9Qm')}>Keep Reading</button>
                        </div>
                    </div>
                    <div className='article-tile'>
                        <img src='https://www.emmonsroofing.com/wp-content/uploads/2018/11/roofing-inspection-double-layer.jpg'/>
                        <div>
                            <h1>The Importance of Regular Roof Maintenance in Houston</h1>
                            <p>Regular roof maintenance is crucial for Houston homeowners to protect their properties from severe weather and extend the lifespan of their roofs.</p>
                            <button onClick={() => navigate('/blog/read/zLON4tWz0lPOiE9B4Zlx')}>Keep Reading</button>
                        </div>
                    </div>
                    {/* Add more static articles if needed */}
                </div>
            </div>
            <div className='all-articles'>
                <div className='article-row'>
                    {Articles}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default BlogMain;