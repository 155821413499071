import { useEffect, useState } from "react";

const Window = ({ type, close }) => {
    const [overlay, setOverlay] = useState(false);

    const PrivacyPolicy = () => {
        return (
            <>
                <h1>PRIVACY POLICY</h1>
                <h2>General Information</h2>
                <p>Welcome to HTEX Roofing. We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose your data when you visit our website at https://htexroofing.com</p>
                <h2>Information We Collect</h2>
                <h3>Personal Information:</h3>
                <ul>
                    <li>Name</li>
                    <li>Phone Number</li>
                    <li>Zip Code</li>
                </ul>
                <h3>Non-Personal Information</h3>
                <ul>
                    <li>Google Analytics Cookies</li>
                </ul>
                <h2>How We Collect Information</h2>
                <ul>
                    <li><span>Forms:</span> Information collected through our contact and estimate request forms.</li>
                    <li><span>Cookies:</span> We use Google Analytics cookies to collect non-personal data about your interaction with our website.</li>
                </ul>
                <h2>Purpose of Data Collection</h2>
                <ul>
                    <li><span>Service Delivery:</span> To schedule estimates and provide our roofing services.</li>
                    <li><span>Communication:</span> To respond to your inquiries and communicate with you regarding our services.</li>
                </ul>
                <h2>Data Sharing and Disclosure</h2>
                <p>We do not share your data with third parties except where required by law.</p>
                <h2>Data Storage and Security</h2>
                <ul>
                    <li><span>Storage:</span> Your data is stored in the Google cloud.</li>
                    <li><span>Security Measures:</span> We implement security measures to protect your data.</li>
                    <li><span>Retention Policy:</span> Data is deleted once you either refuse the quote or after we complete the job.</li>
                </ul>
                <h2>User Rights and Choices</h2>
                <ul>
                    <li><span>Access and Correction:</span> You can request access to and correction of your personal information.</li>
                    <li><span>Data Deletion:</span> If you wish to delete your data, please contact us, and we will handle your request promptly.</li>
                </ul>
                <h2>Children's Privacy</h2>
                <p>Our services are not directed to children under the age of 18. We do not knowingly collect personal information from children.</p>
                <h2>Changes to the Privacy Policy</h2>
                <p>Any changes to our Privacy Policy will be communicated directly to ongoing clients by a team member.</p>
                <h2>Contact Information</h2>
                <p>For any questions or concerns regarding this Privacy Policy, please contact us at:</p>
                <ul>
                    <li><span>Email:</span> [Placeholder Email]</li>
                    <li><span>Phone:</span> [Placeholder Phone Number]</li>
                </ul>
            </>
        );
    };

    const TermsOfService = () => {
        return (
            <>
                <h1>TERMS OF SERVICE</h1>
                <p>Effective Date: 07/28/2024</p>
                <h2>General Information</h2>
                <p>Welcome to HTEX Roofing. These Terms of Service ("Terms") govern your use of our website https://htexroofing.com and our services. By accessing our website or using our services, you agree to comply with these Terms.</p>
                <h2>Acceptance of terms</h2>
                <p>By accessing and using our website or services, you accept and agree to be bound by these Terms. If you do not agree to these Terms, please do not use our website or services.</p>
                <h2>Changes to Terms</h2>
                <p>HTEX Roofing reserves the right to modify these Terms at any time. Any changes will be posted on this page with an updated effective date. Continued use of our website or services after any changes constitutes your acceptance of the new Terms.</p>
                <h2>Use of the Website</h2>
                <h3>Eligibility:</h3>
                <p>You must be at least 18 years old to use our website or services.</p>
                <h3>Permitted Uses:</h3>
                <p>You may use our website to learn about our services, request a quote, and contact us.</p>
                <h3>Prohibited Uses:</h3>
                <p>You may not use our website for any unlawful purpose, to solicit others to perform unlawful acts, or to violate any international, federal, provincial, or state regulations, rules, laws, or local ordinances.</p>
                <h2>Services Provided</h2>
                <p>HTEX Roofing provides residential and commercial roof repairs and replacements. We offer a free quote during a callout to customers. If you agree to proceed with the work, we require a 50% deposit and the remaining balance is due after job completion.</p>
                <h2>User Obligations</h2>
                <p>You agree to provide accurate and up-to-date information when requesting a quote or communicating with us.</p>
                <h2>Payment Terms</h2>
                <h3>Quotes and Pricing</h3>
                <p>Quotes are provided for free during a callout. Pricing is based on the scope of work required.</p>
                <h3>Payment Methods</h3>
                <p>We accept cash, check, credit card, debit card, and wire transfer.</p>
                <h3>Billing and Payment</h3>
                <ul>
                    <li>A 50% deposit is required before any materials are procured for the job.</li>
                    <li>The remaining balance must be paid in full no later than 10 days after job completion.</li>
                </ul>
                <h2>Cancellations and Refunds</h2>
                <ul>
                    <li>If you cancel before materials are purchased, you will receive a refund of your deposit minus $1,000 or 50%, whichever is less.</li>
                    <li>If you cancel after materials are purchased, we will attempt to return the materials. The refunded balance will be the amount from returns minus $1,000 or 50%, whichever is less.</li>
                    <li>If you wish to cancel while work is being performed, this will be discussed between a team member and you to determine if the project can be cancelled.</li>
                </ul>
                <h2>Warranties</h2>
                <h3>Workmanship Warranty</h3>
                <p>HTEX Roofing provides a 10-year workmanship warranty on all our services. This warranty covers defects in the installation and construction of the roofing system.</p>
                <h3>Manufacturer Warranty</h3>
                <p>HTEX Roofing provides a 30-year manufacturer warranty on the materials used for the roofing system. This warranty covers defects in the materials provided by the manufacturer.</p>
                <h2>Intellectual Property</h2>
                <h3>Ownership</h3>
                <p>All content on our website, including text, graphics, logos, and images, is the property of HTEX Roofing and is protected by intellectual property laws.</p>
                <h3>License</h3>
                <p>You are granted a limited license to access and use our website for personal use. You may not reproduce, distribute, or create derivative works from our content without our express written permission.</p>
                <h2>Disclaimers and Limitations of Liability</h2>
                <p>HTEX Roofing shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of our website or services.</p>
                <h2>Governing Law</h2>
                <p>These Terms shall be governed by and construed in accordance with the laws of Texas, United States, without regard to its conflict of law provisions.</p>
                <h2>Contact Information</h2>
                <p>For any questions or concerns regarding these Terms, please contact us at:</p>
                <ul>
                    <li><span>Email:</span> [Placeholder Email]</li>
                    <li><span>Phone:</span> [Placeholder Phone Number]</li>
                </ul>
            </>
        );
    };

    const FeatureNotEnabled = () => {
        return (
            <>
                <h1>FEATURE NOT ENABLED</h1>
                <p>This feature is either functioning incorrectly or has not been fully initialized.  Please check back again soon for more updates.</p>
            </>
        );
    };

    const contentType = () => {
        if (type === 'privacypolicy') {
            return <PrivacyPolicy />;
        } else if (type === 'termsofservice') {
            return <TermsOfService />;
        } else if (type === 'featurenotenabled') {
            return <FeatureNotEnabled />;
        }
        return null;
    };

    useEffect(() => {
        if(type === ''){
            setOverlay(false);
        }
        else{
            setOverlay(true);
        }
    }, [type]);

    return (
        <div className='overlay' style={overlay ? { opacity: '1', pointerEvents: 'all' } : { opacity: '0', pointerEvents: 'none' }}>
            <div className='overlay-content' style={overlay ? { transform: 'translateY(0)' } : { transform: 'translateY(50px)' }}>
                <button onClick={() => close()}>Close</button>
                {contentType()}
            </div>
        </div>
    );
};

export default Window;