import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyAH_6kpvhYCyJSQtWIA9HJhjxhyurCYCDU",
  authDomain: "htexroofing.firebaseapp.com",
  projectId: "htexroofing",
  storageBucket: "htexroofing.appspot.com",
  messagingSenderId: "872726710189",
  appId: "1:872726710189:web:d36c63a091250b17d64324"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);